import { render, staticRenderFns } from "./EntrySearchResultDetail.vue?vue&type=template&id=0196b338&scoped=true&"
import script from "./EntrySearchResultDetail.vue?vue&type=script&lang=js&"
export * from "./EntrySearchResultDetail.vue?vue&type=script&lang=js&"
import style0 from "./EntrySearchResultDetail.vue?vue&type=style&index=0&id=0196b338&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0196b338",
  null
  
)

component.options.__file = "EntrySearchResultDetail.vue"
export default component.exports