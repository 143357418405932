import ProductSearchResultDetail from '../ProductSearchResultDetail.vue';
import WorkSearchResultDetail from '../WorkSearchResultDetail.vue';
import VersionSearchResultDetail from '../VersionSearchResultDetail.vue';


const LENS_WORK = 'work';
const LENS_FINE = 'fine';

const SORT_CANONICAL = 'canonical';
const SORT_WORK_LABEL = 'work_label';
const SORT_RELEVANCE = 'relevance';

const VIPO_SCHEMA = {
  defaultLens: LENS_WORK,
  sortFields: {
    default: SORT_CANONICAL,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_CANONICAL]: {
        label: 'Library Order',
        value: ['se_product_name.keyword', 'canonical_sort'],
      },
      [SORT_WORK_LABEL]: {
        label: 'Work Name',
        value: ['se_work_label.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_CANONICAL) {
      return ['se_product_name.keyword', 'canonical_sort.keyword'];
    } if ($vm.sort === SORT_WORK_LABEL) {
      return ['se_work_label.keyword'];
    } if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_CANONICAL;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.se_product_reference[0]),
  workResultKeyFunc: (result => result.work[0]),
  versionResultKeyFunc: (result => result['text.urn'][0]),
  queries: {
    keyword: '',
    work_labels: '',
    version_labels: '',
  },
  lookups: {
    work_labels: [],
    version_labels: [],
  },
  filters: {
    keyword: false,
    work_labels: null,
    version_labels: null,
  },
  facets: [
    {
      label: 'Work',
      value: 'work_labels',
      toolTipText: 'Filter by work',
      placeholder: '',
    },
    {
      label: 'Edition',
      value: 'version_labels',
      toolTipText: 'Filter by edition',
      placeholder: '',
    },
  ],
  lenses: {
    work: {
      label: 'work',
      pluralLabel: 'works',
      kind: LENS_WORK,
      sortFields: new Set([
        SORT_RELEVANCE,
        SORT_CANONICAL,
        SORT_WORK_LABEL,
      ]),
    },
    version: {
      label: 'version',
      pluralLabel: 'versions',
      kind: 'version',
      sortFields: new Set([
        SORT_RELEVANCE,
        SORT_CANONICAL,
        SORT_WORK_LABEL,
      ]),
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
      sortFields: new Set([
        SORT_RELEVANCE,
        SORT_CANONICAL,
        SORT_WORK_LABEL,
      ]),
    },
  },
  versionLensComponent: VersionSearchResultDetail,
  workLensComponent: WorkSearchResultDetail,
  coarseLensComponent: ProductSearchResultDetail,
};

export default VIPO_SCHEMA;
