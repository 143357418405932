<template>
  <div :class="['text', 'text-body', `text-${textSize}`, `text-width-${textWidth}`]" @mousedown="handleMouseDown">
    <component
      :class="{'text-loading': text === null, 'text-loaded': text !== null}"
      :is="renderedText"
    />
  </div>
</template>

<script>
import WIDGETS_NS from '@scaife-viewer/scaife-widgets';
import CompiledInlineToken from '@scaife-viewer/widget-reader-sv1';

import constants from '../constants';

import InlineToken from './InlineToken.vue';
import TextLoader from '../components/TextLoader.vue';
import TextPart from './TextPart.vue';
import RefLower from './RefLower.vue';
import RenderNote from './RenderNote.vue';
import URN from "@/js/urn";
import {TextPartRequest} from "@/js/tracking";

export default {
  name: 'passage-render-text',
  props: {
    text:{ type: String},
    highlighting: {type: Boolean},
    commentary: {type: Boolean},
    pane: {type: String, default: 'left'},
    setAvailableRefs: {type: Boolean}
  },
  watch: {
    text: 'prepareText',
  },
  data() {
    return {
      renderedText: null
    };
  },
  computed: {
    textSize() {
      return this.$store.state[WIDGETS_NS].readerTextSize;
    },
    textWidth() {
      return this.$store.state[WIDGETS_NS].readerTextWidth;
    },
    passage() {
      return this.$store.getters['reader/passage'];
    },
    leftUrn() {
      return new URN(this.$route.params.urn);
    },
    rightUrn() {
      const { right } = this.$route.query;
      if (!right) {
        return null;
      }
      return this.leftUrn.replace({ version: right });
    },
    parallelUrn() {
      const { parallel_version_urn } = this.$route.query;
      if (!parallel_version_urn) {
        return null;
      }
      const parallel_urn = new URN(parallel_version_urn)
      // The parallelUrn does not contain the reference. We need to add it for correct counting in Liblynx.
      parallel_urn.reference = this.leftUrn.reference
      return parallel_urn;
    },
    showCommentaryWidget() {
      const flagSet = document.querySelectorAll('[data-show-commentary-widget=true]').length > 0;
      if (flagSet) {
        return true;
      }
      return this.$route.query.show_commentary_widget === 'y';
    },
    tokenComponent() {
      // FIXME: Only use CompiledInlineToken where required
      return this.showCommentaryWidget && this.passage && this.passage.urn.textGroup === 'stoa0023'
        ? CompiledInlineToken
        : InlineToken;
    },
  },
  provide() {
    return {
      // injected in node_modules/@scaife-viewer/widget-reader-sv1/src/CompiledToken.vue
      highlighting: this.highlighting,
      commentary: this.commentary,
    };
  },
  created() {
    this.prepareText();
  },
  methods: {
    handleMouseDown(e) {
      if (this.highlighting && this.$store.state.reader.textMode === 'clickable') {
        e.preventDefault();
      }
    },
    prepareText() {
      if (this.text === null) {
        // give the text fade out animation time to complete before
        // we show the loader.
        const delay = 250;
        setTimeout(() => {
          // check text before setting loader because it may have been
          // loaded faster than our delay.
          if (this.text === null) {
            this.renderedText = TextLoader;
          }
        }, delay);
      } else {
        this.$nextTick(this.renderText);
      }
    },
    extractUniqueTopRefs(refArray) {
        const refs = [...new Set(refArray)];
        const topRefs = []
        refs.forEach( (ref) => {
          if( ref.includes('.')) {
            topRefs.push(ref.split('.')[0])
          } else {
            topRefs.push(ref)
          }
        });
        return [...new Set(topRefs)]
    },
    handleLiblynxCount(context, availableRefs) {

      if( context.pane === 'left') {
        const leftText = context.$store.state.reader.leftText
        const baseUrnLeft = context.leftUrn;
        const uniqueTopRefs = this.extractUniqueTopRefs(availableRefs);

        const ssLeft = sessionStorage.getItem('last-reported-left');
        const ssTotalLeft = sessionStorage.getItem('last-total-left');
        const lastReportedLeft = ssLeft ? JSON.parse(ssLeft) : [];
        const lastTotalLeft = ssTotalLeft ? parseInt(ssTotalLeft) : 0;

        const reportedLeft = [];
        const lengthChangedLeft = lastTotalLeft !== uniqueTopRefs.length

        uniqueTopRefs.forEach( (ref) => {
          baseUrnLeft.reference = ref;
          const idx = lastReportedLeft.findIndex( (ref) => ref === baseUrnLeft.upTo('reference'))
          if( idx < 0 || lengthChangedLeft ) {
            this.$counter.track('Request', TextPartRequest({ ...leftText, urn: baseUrnLeft.upTo('reference') }));
          }
          reportedLeft.push(baseUrnLeft.upTo('reference'))
        })

        sessionStorage.setItem('last-reported-left', JSON.stringify(reportedLeft))
        sessionStorage.setItem('last-total-left', uniqueTopRefs.length)

        if( this.$route.query.parallel_version_urn) {

          let parallelText = '';
          if( context.passage && context.passage.metadata.parallel_version ) {
            parallelText = {
              metadata: context.passage.metadata.parallel_version,
              urn: ''
            }
            parallelText.metadata.product = leftText.metadata.product
            parallelText.metadata.product_reference = leftText.metadata.product_reference
            parallelText.metadata.manifest = leftText.metadata.manifest
          }

          const baseUrnParallel = context.parallelUrn;
          const parallelRefs = Object.keys(context.passage.metadata.parallel_passage_lu);
          const uniqueTopRefs = this.extractUniqueTopRefs(parallelRefs);

          const ssParallel = sessionStorage.getItem('last-reported-parallel');
          const ssTotalParallel = sessionStorage.getItem('last-total-parallel');
          const lastReportedParallel = ssParallel ? JSON.parse(ssParallel) : [];
          const lastTotalParallel = ssTotalParallel ? parseInt(ssTotalParallel) : 0;

          const reportedParallel = [];
          const lengthChangedParallel = lastTotalParallel !== uniqueTopRefs.length

          uniqueTopRefs.forEach((ref) => {
            baseUrnParallel.reference = ref;
            const idx = lastReportedParallel.findIndex( (ref) => ref === baseUrnParallel.upTo('reference'))
            if( idx < 0 || lengthChangedParallel ) {
              this.$counter.track('Request', TextPartRequest({...parallelText, urn: baseUrnParallel.upTo('reference')}));
            }
            reportedParallel.push(baseUrnParallel.upTo('reference'))
          })
          sessionStorage.setItem('last-reported-parallel', JSON.stringify(reportedParallel))
          sessionStorage.setItem('last-total-parallel', uniqueTopRefs.length)
        }
      }
      if( context.pane === 'right') {
        const rightText = context.$store.state.reader.rightText
        const baseUrnRight = context.rightUrn;
        const uniqueTopRefs = this.extractUniqueTopRefs(availableRefs);

        const ssRight = sessionStorage.getItem('last-reported-right');
        const ssTotalRight = sessionStorage.getItem('last-total-right');
        const lastReportedRight = ssRight ? JSON.parse(ssRight) : [];
        const lastTotalRight = ssTotalRight ? parseInt(ssTotalRight) : 0;

        const reportedRight = [];
        const lengthChangedRight = lastTotalRight !== uniqueTopRefs.length

        uniqueTopRefs.forEach((ref) => {
          baseUrnRight.reference = ref;
          const idx = lastReportedRight.findIndex( (ref) => ref === baseUrnRight.upTo('reference'))
          if( idx < 0 || lengthChangedRight ) {
            this.$counter.track('Request', TextPartRequest({...rightText, urn: baseUrnRight.upTo('reference')}));
          }
          reportedRight.push(baseUrnRight.upTo('reference'))
        })
        sessionStorage.setItem('last-reported-right', JSON.stringify(reportedRight))
        sessionStorage.setItem('last-total-right', uniqueTopRefs.length)
      }
    },
    renderText() {
      let observer = null;
      let self=this;

      this.renderedText = {
        template: this.text,
        created() {
          const opts = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
          };
          observer = new IntersectionObserver(this.ioCallback, opts);
        },
        mounted() {
          this.$nextTick(() => {
            // TODO: Encapsulate this elsewhere
            const availableRefs = [];
            this.$el.querySelectorAll && this.$el.querySelectorAll('.textpart.o').forEach((e) => {
              observer.observe(e);
              const anchor = e.querySelector('a')
              if( anchor && anchor.href) {
                const url = new URL(e.querySelector('a').href);
                const availableRef = decodeURI(url.pathname.split(':').slice(-1)[0]);
                availableRefs.push(availableRef);
              }
            });
            if( self.setAvailableRefs ) {
              this.$store.commit(`reader/${constants.SET_AVAILABLE_REFS}`, { availableRefs });
            }

            self.handleLiblynxCount(self, availableRefs)
          });
        },
        destroy() {
          observer.disconnect();
          observer = null;
        },
        methods: {
          ioCallback(entries) {
            entries.forEach((entry) => {
              if (entry) {
                const vm = entry.target.__vue__; // eslint-disable-line no-underscore-dangle
                if (vm) {
                  vm.visible = entry.isIntersecting;
                }
              }
            });
          },
        },
        components: {
          RefLower,
          RenderNote,
          TextPart,
          t: this.tokenComponent,
        },
      };
    },
  },
};
</script>
