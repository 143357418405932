import SEGEntrySearchResultDetail from '../SEGEntrySearchResultDetail.vue';

const LENS_COARSE = 'coarse';

const SORT_RELEVANCE = 'relevance';
const SORT_PUBLICATION_DATE = 'publicationDate';
const SEG_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: 'publicationDate',
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_PUBLICATION_DATE]: {
        label: 'Publication Date',
        value: ['-seg_volume', 'urn'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    if ($vm.sort === SORT_PUBLICATION_DATE) {
      return ['-seg_volume', 'urn'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_PUBLICATION_DATE;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.text.urn),
  queries: {
    keyword: '',
    places: '',
    date_labels: '',
    index_terms: '',
    work_labels: '',
    volumes: '',
    types: '',
    concordance_types: '',
    concordance_labels: '',
    not_before: '',
    not_after: '',
  },
  lookups: {
    places: [],
    date_labels: [],
    index_terms: [],
    work_labels: [],
    volumes: [],
    types: [],
    concordance_types: [],
    concordance_labels: [],
  },
  filters: {
    keyword: false,
    not_before: false,
    not_after: false,
    places: null,
    date_labels: null,
    index_terms: null,
    work_labels: null,
    volumes: null,
    types: null,
    concordance_types: null,
    concordance_labels: null,
  },
  facets: [
    {
      label: 'Entry Title',
      value: 'work_labels',
      toolTipText: 'Search a SEG entry by its number and/or title (if by number, ensure to add the full stop, e.g. 66-100., for an exact match)',
      placeholder: '',
    },
    {
      label: 'SEG Volume',
      value: 'volumes',
      toolTipText: 'Search by SEG volume number',
    },
    {
      label: 'Place',
      value: 'places',
      multiple: true,
      flatten: true,
      toolTipText: 'Search for geographical place names by city, region, or country',
    },
    {
      label: 'Period',
      value: 'date_labels',
      toolTipText: 'Please use one of the six general periods (Archaic, Classical, Hellenistic, Roman, Late Antique, Byzantine) or (part of) centuries, such as II AD',
    },
    // TODO: Link this field to `get_date_range_field` server side
    {
      kind: 'dateRange',
      min: {
        badgeLabel: 'Year >=',
        filterLabel: 'From',
        value: 'not_before',
        // TODO: Make placeholder match actual possible range?
        placeholder: '-300',
      },
      max: {
        badgeLabel: 'Year <=',
        filterLabel: 'Until',
        value: 'not_after',
        // TODO: Make placeholder match actual possible range?
        placeholder: '500',
      },
    },
    {
      kind: 'operatorField',
      label: 'Index terms',
      value: 'index_terms',
      flatten: true,
      multiple: true,
      operator: true,
      toolTipText: 'Search for a specific index term',
    },
    {
      label: 'Inscription Type',
      value: 'concordance_types',
      toolTipText: 'Choose one of the 11 inscription types',
    },
    {
      label: 'Concordances',
      value: 'concordance_labels',
      toolTipText: 'Search for a specific inscription (IG-numbers follow the format “IG II-3 670”)',
    },
    {
      label: 'Entry Type',
      value: 'types',
      toolTipText: 'Choose one of the 4 entry types',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
  },
  coarseLensComponent: SEGEntrySearchResultDetail,
};

export default SEG_SCHEMA;
