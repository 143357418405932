<template>
  <div
    v-if="links_new_tab"
    class="list-group-item list-group-item-action"
  >
    <div class="row">
      <div class="col-11">
        <div class="passage-heading">
          <h5 class="title">
            <span><a :href="createPassageLink(result.urn)">{{ passageLabel }}</a></span>
          </h5>
        </div>
        <div
          v-for="content in contents"
          :key="content"
          :class="{'content-preview': !hasHighlights}"
          class="search-content"
          v-html="content"
        />
      </div>
      <div class="col-1">
        <a :onclick="createButtonAction">
          <icon name="window-restore" />
        </a>
      </div>
    </div>
  </div>
  <a
    :href="createPassageLink(result.urn)"
    class="passage-result list-group-item list-group-item-action"
    v-else
  >
    <div class="passage-heading">
      <h5 class="title">
        <span>{{ passageLabel }}</span>
      </h5>
    </div>
    <div
      v-for="content in contents"
      :key="content"
      :class="{'content-preview': !hasHighlights}"
      class="search-content"
      v-html="content"
    />
  </a>
</template>
<script>
import URN from '../../urn';

export default {
  props: ['result', 'queries', 'links_new_tab'],
  computed: {
    keywordQuery() {
      return this.queries.keyword || false;
    },
    passageLabel() {
      // TODO: Factor this out to collection schema on both the front and back ends.
      if (this.result.cso_work_label) {
        return `${this.result.cso_tradition}, ${this.result.cso_work_label}, ${this.result.text.label} (${this.passageRef})`;
      }
      if (this.result.dss_work_label) {
        return `${this.result.dss_work_label}, ${this.result.text.label} (${this.passageRef})`;
      }
      if (this.result.bnjo_entry_title) {
        return `${this.result.bnjo_entry_title}, ${this.result.text.label} (${this.passageRef})`;
      }
      if (this.result.pez_work_label) {
        return `${this.result.pez_work_label}, ${this.result.text.label} (${this.passageRef})`;
      }
      if (this.result.se_product_name) {
        const t = this.result.text;
        if( t.urn.includes(':vipo')) {
          if( t.kind === 'edition') {
            t.kind = "article"
          }
          else if( t.kind === 'translation') {
            t.kind = "decree"
          }
          else if( t.kind === 'commentary') {
                  t.kind = "questionnaire"
          }
        }
        return `${this.result.se_text_group_label}, ${this.result.se_work_label}, ${t.human_lang} ${t.kind} (${this.passageRef})`;
      }
      return this.result.urn;
    },
    passageRef() {
      const urnObj = new URN(this.result.urn);
      return urnObj.reference;
    },
    contents() {
      return this.hasHighlights ? this.result.highlights : [this.result.content];
    },
    hasHighlights() {
      return this.result.highlights.length > 0;
    },
    createButtonAction() {
      const url = this.createPassageLink(this.result.urn);
      return `window.open('${url}','_blank')`;
    },
  },
  methods: {
    readerPassageUrl(urn) {
      // NOTE: In SEG, we are redirecting to the first passage,
      // but here we have passage URNs
      return `${this.$router.options.base}reader/${urn}`;
    },
    readerPassageKeywordSearchUrl(urn) {
      const searchType = 'form';
      const passageUrl = this.readerPassageUrl(urn);
      const searchQuery = this.queries.keyword || '';
      return `${passageUrl}?q=${searchQuery}&qk=${searchType}`;
    },
    createPassageLink(urn) {
      return this.keywordQuery
        ? this.readerPassageKeywordSearchUrl(urn)
        : this.readerPassageUrl(urn);
    },
  },
};
</script>

<style lang="scss" scoped>
  // TODO: Refactor using sv- prefix CSS
  @import "../../../scss/config.scss";

.passage-result {
  > .passage-heading {
    font-family: $font-family-primary;
    h2 {
      // TODO: _search.css
      margin: 0 0 0.5rem;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
    }
  }
  > .passage-heading > * {
      // TODO: collision with body.library styles
      text-transform: unset;
      font-family: inherit;
      letter-spacing: normal;
  }
  .search-content /deep/ {
    // TODO: _config.css
    em {
          background-color: $highlight-color;
          font-style: normal;
      }
    // TODO: _search.css
    font-family: $font-family-primary;
    font-size: 18px;
    line-height: 1.8;
  }
  .search-content.content-preview {
    // NOTE: Just show the first line of results
    // without highlights
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
