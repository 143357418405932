export const TextPartRequest = ({ metadata, urn }) => {
  const payload = {
    tm: {
      id: metadata.product,
      title: metadata.tracking_title,
      dtype: 'book',
    },
    im: {
      // log the urn being viewed here?
      id: urn,
      title: metadata.label,
      dtype: 'segment',
    },
  };

  const { manifest } = metadata;
  if (manifest && manifest.issn) {
    payload.im.issno = manifest.issn;
  }

  const productReference = metadata.product_reference;
  if (productReference && productReference.reference) {
    payload.dbs = [
      {
        dbid: productReference.reference,
        dbname: productReference.name,
      },
    ];
  }

  return payload;
};

export const TextPartSearch = ({ metadata }) => ({
  dbs: [
    {
      dbid: metadata.product_reference.reference,
      dbname: metadata.product_reference.name,
    },
  ],
});

/**
 * TextGroupSearch extracts db (product) information from the manifest
 * attached to the atlas textgroup node's metadata.
 */
export const TextGroupSearch = ({ metadata }) => ({
  dbs: [
    {
      dbid: metadata.manifest.reference,
      dbname: metadata.manifest.name,
    },
  ],
});

/**
 * GlobalSearch collects all dbs (products) available on the platform
 * and maps name and reference to db entries for counter.
 */
export const GlobalSearch = products => ({
  dbs: products.map(product => ({ dbid: product.reference, dbname: product.name })),
  sa: true, // no explicit database chosen "automated search"
});

export const MetaSearch = (meta) => {
  const dbs = [];
  const references = meta.product_reference_multi && meta.product_reference_multi.length
    ? meta.product_reference_multi : [];
  const names = meta.product_name && meta.product_name.length ? meta.product_name : [];

  if (references.length === names.length) {
    for (let idx = 0; idx < references.length; idx += 1) {
      dbs.push({ dbid: references[idx], dbname: names[idx] });
    }
  } else {
    for (let idx = 0; idx < references.length; idx += 1) {
      dbs.push({ dbid: references[idx], dbname: '' });
    }
  }

  const uniqueDbs = Array.from(new Set(dbs.map(JSON.stringify))).map(JSON.parse);

  return {
    dbs: uniqueDbs,
    sa: true, // no explicit database chosen "automated search"
  };
};
