<template>
  <section v-if="selectedMetadata">
    <div
          v-for="refObj in selectedMetadata"
          :key="refObj.id"
          class="by-ref-group"
        >
      <div
          :class="{'selected': isSelected(refObj.ref)}"
          class="textpart-ref"
          @click="toggleSelectedRef(refObj.ref)"
        >
          {{ refObj.ref }}
      </div>
      <div
          v-if="side !== ''"
          class="textpart-ref"
          @click="toggleSelectedRef(refObj.ref)"
        >
          <p class="side">{{side}}</p>
      </div>
      <div class="group-container">
        <div
            v-for="(records, label) in refObj.recordsByLabel"
            :key="label"
            class="group"
          >
            <div class="label">
              {{ capFirst(dehydrated(label)) }}:
            </div>
            <div class="values-container">
              <div
                v-for="record in records"
                :key="record.id"
                class="value"
                v-html="formatAndDisplay(record)"
              />
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from "@/js/constants";
import URN from "@/js/urn";

export default {
  name: 'MetadataRecordPez',
  props: {
    selectedMetadata: {
        type: Array,
        default: () => []
    },
    side: {
      type: String,
      default: ''
    },
    urn: {
      type: Object
    },
    manifest: {
      type: Object
    }
  },
  computed: {
    selectedRefs() {
      return this.$store.getters['reader/selectedRefs'];
    },
  },
  methods: {
    capFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    dehydrated(value) {
      // TODO: Improve verbose metadata labels
      return value.split('_').join(' ');
    },
    toggleSelectedRef(ref) {
      // TODO: Support multiple selection and refactor with TextPart.vue
      if (this.isSelected(ref)) {
        this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_REF}`, { ref: null });
      } else {
        this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_REF}`, { ref });
      }
    },
    isSelected(ref) {
      return this.selectedRefs.includes(ref);
    },
    formatAndDisplay(record) {
      const data = Object.assign({}, record);
      const key = data.label
      if( data.datatype === 'obj') {
        const obj = JSON.parse(data.valueObj)
        if( key ==='date_sent') {
          if (obj.type === 'notAfter') {
            data.value = "&lt; " + obj.date;
          } else if (obj.type === 'notBefore') {
            data.value = " &gt; " + obj.date;
          } else {
            data.value = obj.date;
          }
        } else if( this.manifest && this.manifest.mappings && ['attachment', 'third_party_letter'].includes(key)) {
          const [band, entryNr] = obj.ref.split(":")
          const mappings = this.manifest.mappings;

          let pageUrl = '';
          if( obj.type === 'attachment' && mappings.attachments[band]) {
            pageUrl = mappings.attachments[band]
          } else if( obj.type === 'thirdpartyletter' && mappings.thirdpartyletters[band]) {
            pageUrl = mappings.thirdpartyletters[band]
          }

          if( obj.ref !== "" && pageUrl !== "") {
            data.value = `<a href="/${pageUrl}#${entryNr}" target="_blank">${obj.title}</a>`;
          } else {
            data.value = obj.title;
          }
        } else {
          if( obj.ref !== "") {
            data.value = `<a href="${obj.ref}" target="_blank">${obj.name}</a>`;
          } else {
            data.value = obj.name;
          }
        }
      } else {
        if(['next', 'previous', 'mentions', 'mentioned_in', 'sent_with', 'in_tradition_of', 'unknown_relation'].includes(key)) {
          const targetUrn = new URN(this.urn.absolute)
          const letterBand = data.value.split("-")
          const letter = letterBand[0]
          targetUrn.work = String(letter).padStart(4, '0');

          // Target version might have a different band number compared to current version
          let versionParts = targetUrn.version.split("-")
          versionParts[1] = letterBand[1]
          targetUrn.version = versionParts.join("-")
          let link= targetUrn.upTo('version')
          data.value = `<a href="/reader/${link}">${letter}</a>`
        }
      }
      return data.value
    }
  }

}
</script>

<style lang="scss" scoped>
  // TODO: Implement proper variables
  .by-ref-group {
    // NOTE: CSS variables would be used to customize fonts under SV 2
    font-family: var(
      --sv-widget-metadata-records-font-family,
      "Noto Sans",
      "Noto Sans Coptic"
    );
    font-size: 14px; // NOTE: This was taken from the SV2 metadata widget
    margin-block-end: 10px;
    display: flex;
    padding: 4px;
  }
  .textpart-ref {
    // TODO: $gray-600;
    color: #868e96;
    padding: 4px;
    cursor: pointer;
    min-width: 3rem;
    // TODO: Mimic hover in TextPart.vue
  }
  .group-container {
    width: 100%;
  }
  .group {
    display: flex;
    padding: 2px;
  }
  .label {
    padding: 2px;
    // NOTE: sidebar max is 600px, so 30% is 180px
    // TODO: Any other calculations we can do here?
    width: clamp(80px, 30%, 180px);
    flex-shrink: 0;
    text-align: right;
    font-weight: bold;
  }
  .values-container {
    padding-inline-start: 8px;
  }
  .value {
    padding: 2px;
  }
  .selected {
    color: #495057;
    background-color: #e9ecef;
  }
</style>